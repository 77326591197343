<template>
  <div class="row justify-content-between">
    <div class="form-group col-md-12 text-right"
      v-if="
        $store.getters.can('cs.tarifarios.createTarifasTransportes') ||
        $store.getters.can('cs.tarifarios.editTarifasTransportes')
      "
    >
      <button
        data-target="#Modal_transportes"
        data-toggle="modal"
        class="btn btn-primary"
        v-if="$store.getters.can('cs.tarifarios.createTarifasTransportes')"
        @click="getFormTarifa()"
      >
        <i class="fas fa-plus"></i>
      </button>
      <button
        data-target="#Modal_transportes_formato"
        data-toggle="modal"
        class="btn btn-success"
        v-if="
          $store.getters.can('cs.tarifarios.createTarifasTransportes') ||
          $store.getters.can('cs.tarifarios.editTarifasTransportes')
        "
        @click="getFormFormato()"
      >
        <i class="fa fa-file-excel"></i>
      </button>
    </div>
    <div class="col-md-12">
      <table class="table table-bordered table-striped table-hover table-sm mb-0"
        v-if="$parent.tarifas_transportes.length > 0"
      >
        <thead class="thead-dark">
          <tr>
            <th class="col-2">Tipo Vehículo</th>
            <th class="col-1">Tipo Tarifa</th>
            <th>Ruta</th>
            <th class="col-1">Km Inicial</th>
            <th class="col-1">Km Final</th>
            <th class="col-2">Vigencia</th>
            <th class="col-1">Valor</th>
            <th class="col-1">Estado</th>
            <th class="col-1">Acciones</th>
          </tr>
        </thead>
        <tbody
          v-for="tip_vehi in $parent.tarifas_transportes"
          :key="tip_vehi.tipo_vehiculo_id"
        >
          <tr
            v-for="(tarifa, index) in tip_vehi.data"
            :key="tarifa.id"
          >
            <td v-if="index === 0"
              :rowspan="tip_vehi.data.length"
            >
              {{
                tarifa.tipo_vehiculo
                  ? tarifa.tipo_vehiculo.nombre
                  : "No establecido"
              }}
            </td>
            <td class="text-center">
              <span
                class="badge"
                :class="tarifa.tipo_tarifa == 1 ? 'bg-info' : 'bg-navy'"
              >
                {{ tarifa.nTipoTarifa }}
              </span>
            </td>
            <td>{{ tarifa.ruta ? tarifa.ruta.nombre : "-" }}</td>
            <td class="text-center">{{ tarifa.km_inicial ? tarifa.nKmInicial : "-" }}</td>
            <td class="text-center">{{ tarifa.km_final ? tarifa.nKmFinal : "-" }}</td>
            <td class="text-center text-nowrap">
              {{
                tarifa.detalles.length > 0
                  ? `${tarifa.detalles[0].fecha_ini} - ${tarifa.detalles[0].fecha_fin}`
                  : ""
              }}
            </td>
            <td class="text-right">
              {{ tarifa.detalles.length > 0 ? tarifa.detalles[0].valor : "" }}
            </td>
            <td class="text-center">
              <span
                class="badge"
                v-bind:class="[
                  tarifa.estado == 1 ? 'badge-success' : 'badge-danger',
                ]"
              >
                {{ tarifa.nEstado }}
              </span>
            </td>
            <td class="text-right">
              <div class="btn-group">
                <button
                  data-target="#Modal_transportes"
                  data-toggle="modal"
                  class="btn btn-sm bg-navy"
                  v-if="
                    $store.getters.can('cs.tarifarios.editTarifasTransportes') && 
                    $parent.estado_tarifario == 1
                  "
                  @click="getFormTarifa(tarifa)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  data-target="#Modal_transportes_detalles"
                  data-toggle="modal"
                  class="btn btn-sm bg-info"
                  v-if="$store.getters.can('cs.tarifarios.editTarifasTransportes')"
                  @click="getDataDet(tarifa)"
                >
                  <i class="fas fa-list"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="callout callout-warning text-muted m-0">
        <h5 class="m-0">No hay tarifas registradas!</h5>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="Modal_transportes_formato">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalTransporteFormato"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">
            <div class="row">
              <div class="form-group col-md-12">
                <button
                  id="formatoTarifaTransporte"
                  type="button"
                  class="btn bg-olive text-nowrap"
                  data-html="true"
                  title="Formato de Traifa de Transporte"
                  @click="descargarFormatoTarifaTransporte"
                >
                  <i class="fa fa-file-excel mr-1"></i>
                  <small>Descargar Formato</small>
                </button>
              </div>
              <div class="form-group col-md-12">
                <label class="m-0">Carga masiva de Tarifas</label>
                <input
                  type="file"
                  id="link_formato"
                  ref="file"
                  class="form-control btn btn-light col-12"
                  v-on:change="elegirDirectorio()"
                  accept=".XLSX, .CSV"
                  :class="$v.form_file.file.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="alert alert-warning alert-dismissible">
                <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
                Cambiar la columna <strong>Id</strong> del formato afectará el comportamiento de las liquidaciónes, 
                favor abstenerse de cambiar esta columna.
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between"
            v-if="
              !$v.form_file.file.$invalid &&
              ($store.getters.can('cs.tarifarios.createTarifasTransportes') ||
                $store.getters.can('cs.tarifarios.editTarifasTransportes'))
            "
          >
          <button class="btn bg-navy"
            @click="cargarTarifas"
          >
            <i class="fas fa-upload"></i>
          </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="Modal_transportes">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalTransporte"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Tipo Vehículo</label>
                <select
                  v-model="form_tarifa.tipo_vehiculo_id"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.tipo_vehiculo_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can('cs.tarifarios.createTarifasTransportes') && !form_tarifa.id) ||
                    (!$store.getters.can('cs.tarifarios.editTarifasTransportes') && form_tarifa.id)
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tip_veh in listasForms.tipos_vehiculos"
                    :key="tip_veh.id"
                    :value="tip_veh.id"
                  >
                    {{ tip_veh.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Tipo Tarifa</label>
                <select
                  v-model="form_tarifa.tipo_tarifa"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.tipo_tarifa.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can('cs.tarifarios.createTarifasTransportes') && !form_tarifa.id) ||
                    (!$store.getters.can('cs.tarifarios.editTarifasTransportes') && form_tarifa.id)
                  "
                  @change="resetTipVehFecha"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tip_tarif in listasForms.tipos_tarifas"
                    :key="tip_tarif.numeracion"
                    :value="tip_tarif.numeracion"
                  >
                    {{ tip_tarif.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-12" v-if="form_tarifa.tipo_tarifa == 1">
                <label>Ruta</label>
                <select
                  v-model="form_tarifa.cs_ruta_id"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.cs_ruta_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can('cs.tarifarios.createTarifasTransportes') && !form_tarifa.id) ||
                    (!$store.getters.can('cs.tarifarios.editTarifasTransportes') && form_tarifa.id)
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="ruta in listasForms.rutas"
                    :key="ruta.id"
                    :value="ruta.id"
                  >
                    {{ ruta.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6" v-if="form_tarifa.tipo_tarifa == 2">
                <label>Km Iniciales</label>
                <select v-model="form_tarifa.km_inicial"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.km_inicial.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can('cs.tarifarios.createTarifasTransportes') && !form_tarifa.id) ||
                    (!$store.getters.can('cs.tarifarios.editTarifasTransportes') && form_tarifa.id)
                  "
                  @change="validaKm"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="km_ini in listasForms.km_iniciales"
                    :key="km_ini.numeracion"
                    :value="km_ini.numeracion"
                  >
                    {{ km_ini.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6" v-if="form_tarifa.tipo_tarifa == 2">
                <label>Km Finales</label>
                <select v-model="form_tarifa.km_final"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.km_final.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can('cs.tarifarios.createTarifasTransportes') && !form_tarifa.id) ||
                    (!$store.getters.can('cs.tarifarios.editTarifasTransportes') && form_tarifa.id)
                  "
                  @change="validaKm"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="km_fin in listasForms.km_finales"
                    :key="km_fin.numeracion"
                    :value="km_fin.numeracion"
                  >
                    {{ km_fin.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label>Estado</label>
                <select
                  v-model="form_tarifa.estado"
                  class="form-control form-control-sm"
                  :class="
                    $v.form_tarifa.estado.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="
                    !form_tarifa.id ||
                    (!$store.getters.can('cs.tarifarios.createTarifasTransportes') && !form_tarifa.id) ||
                    (!$store.getters.can('cs.tarifarios.editTarifasTransportes') && form_tarifa.id)
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between"
            v-if="
              !$v.form_tarifa.$invalid &&
              ($store.getters.can('cs.tarifarios.createTarifasTransportes') ||
                $store.getters.can('cs.tarifarios.editTarifasTransportes'))
            "
          >
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="Modal_transportes_detalles">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalTransporteDetalle"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-right mb-2">
                <button
                  class="btn btn-primary"
                  @click="getFormStatusDet(1)"
                  :disabled="flag_form_detalle != 0"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
              <div class="col-md-12">
                <table
                  class="table table-bordered table-striped table-hover table-sm mb-0"
                  v-if="det_tarifa.length > 0 || flag_form_detalle != 0"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th class="col-3">Fecha Inicial</th>
                      <th class="col-3">Fecha Final</th>
                      <th class="col-4">Valor</th>
                      <th class="col-1">Estado</th>
                      <th class="col-1">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="flag_form_detalle == 1">
                      <td class="text-center">
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form_detalle.fecha_ini"
                          :class="
                            $v.form_detalle.fecha_ini.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechas(null)"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="form_detalle.fecha_fin"
                          :class="
                            $v.form_detalle.fecha_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechas(null)"
                        />
                      </td>
                      <td class="text-right">
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="form_detalle.valor"
                          :class="
                            $v.form_detalle.valor.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </td>
                      <td class="text-center">
                        <span class="badge badge-success">ACTIVO</span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-sm bg-success"
                            v-if="
                              !$v.form_detalle.$invalid &&
                              $store.getters.can(
                                'cs.tarifarios.editTarifasTransportes'
                              )
                            "
                            @click="saveDetalle"
                          >
                            <i class="fas fa-save"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-danger"
                            @click="getFormStatusDet(0)"
                          >
                            <i class="fas fa-ban"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-for="(det, index) in det_tarifa" :key="index">
                      <td class="text-center">
                        <input
                          type="date"
                          v-if="index === 0 && flag_form_detalle == 2"
                          class="form-control form-control-sm"
                          v-model="form_detalle.fecha_ini"
                          :class="
                            $v.form_detalle.fecha_ini.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechas(index)"
                        />
                        {{
                          index !== 0 || flag_form_detalle != 2
                            ? det.fecha_ini
                            : ""
                        }}
                      </td>
                      <td class="text-center">
                        <input
                          type="date"
                          v-if="index === 0 && flag_form_detalle == 2"
                          class="form-control form-control-sm"
                          v-model="form_detalle.fecha_fin"
                          :class="
                            $v.form_detalle.fecha_fin.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @input="validaFechas(index)"
                        />
                        {{
                          index !== 0 || flag_form_detalle != 2
                            ? det.fecha_fin
                            : ""
                        }}
                      </td>
                      <td class="text-right">{{ det.valor }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            det.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ det.nEstado }}
                        </span>
                      </td>
                      <td class="text-right">
                        <div
                          class="btn-group"
                          v-if="index === 0 && flag_form_detalle == 0"
                        >
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            @click="getFormStatusDet(2, index)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                        <div
                          class="btn-group"
                          v-if="index === 0 && flag_form_detalle == 2"
                        >
                          <button
                            type="button"
                            class="btn btn-sm bg-success"
                            v-if="
                              !$v.form_detalle.$invalid &&
                              $store.getters.can(
                                'cs.tarifarios.editTarifasTransportes'
                              )
                            "
                            @click="saveDetalle"
                          >
                            <i class="fas fa-save"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-danger"
                            @click="getFormStatusDet(0)"
                          >
                            <i class="fas fa-ban"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="callout callout-warning text-muted m-0">
                  <h5 class="m-0">No hay detalles de tarifa registrados!</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CsTarifariosTarifasTransporte",

  components: {},

  data() {
    return {
      cs_tarifario_id: null,
      cs_tarifa_transporte_id: null,
      tarifas_transportes: [],
      tarifa_estado: null,
      modal_title: "",
      listasForms: {
        tipos_tarifas: [],
        tipos_vehiculos: [],
        rutas: [],
        km_iniciales: [],
        km_finales: [],
        estados: [],
      },
      form_tarifa: {},
      flag_form_detalle: 0,
      det_tarifa: {},
      form_file: {
        file: null,
      },
      form_detalle: {},
    };
  },

  validations() {
    let tarifa = {};
    switch (this.form_tarifa.tipo_tarifa) {
      case 1:
        tarifa = {
          tipo_tarifa: {
            required,
          },
          cs_ruta_id: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
          estado: {
            required,
          },
        };
        break;

      case 2:
        tarifa = {
          tipo_tarifa: {
            required,
          },
          km_inicial: {
            required,
          },
          km_final: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
          estado: {
            required,
          },
        };
        break;

      default:
        tarifa = {
          tipo_tarifa: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
          estado: {
            required,
          },
        };
        break;
    }

    return {
      form_tarifa: tarifa,
      form_file: {
        file: {
          required,
        }
      },
      form_detalle: {
        valor: {
          required,
        },
        fecha_ini: {
          required,
        },
        fecha_fin: {
          required,
        },
        estado: {
          required,
        },
      },
    };
  },

  methods: {
    getIndex() {
      this.cs_tarifario_id = this.$parent.datos_tarifario.id;
    },

    // Funciones para llenado de las listas
    getTiposTarifas() {
      axios.get("/api/lista/168").then((response) => {
        this.listasForms.tipos_tarifas = response.data;
      });
    },

    getTipoVehiculos() {
      const me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 4,
          },
        })
        .then(function (response) {
          me.listasForms.tipos_vehiculos = response.data;
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getRutas() {
      const me = this;
      axios
        .get("api/cs/rutas/lista")
        .then(function (response) {
          me.listasForms.rutas = response.data;
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getKmIniciales() {
      axios.get("/api/lista/169").then((response) => {
        this.listasForms.km_iniciales = response.data;
      });
    },

    getKmFinales() {
      axios.get("/api/lista/170").then((response) => {
        this.listasForms.km_finales = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    // Funciones para los formularios
    getFormTarifa(tarifa = null) {
      this.modal_title = `Crear Tarifa`;
      this.resetFormTarifa();
      if (tarifa) {
        this.modal_title = `Editar Tarifa`;
        this.form_tarifa = {
          id: tarifa.id,
          cs_tarifario_id: tarifa.cs_tarifario_id,
          tipo_tarifa: tarifa.tipo_tarifa,
          km_inicial: tarifa.km_inicial,
          km_final: tarifa.km_final,
          cs_ruta_id: tarifa.cs_ruta_id,
          tipo_vehiculo_id: tarifa.tipo_vehiculo_id,
          estado: tarifa.estado,
        };
      }
    },

    resetFormTarifa() {
      this.form_tarifa = {
        id: null,
        cs_tarifario_id: this.cs_tarifario_id,
        tipo_tarifa: null,
        km_inicial: null,
        km_final: null,
        cs_ruta_id: null,
        tipo_vehiculo_id: null,
        estado: 1,
      };
    },

    resetTipVehFecha(){
      this.form_tarifa.cs_ruta_id = null;
      this.form_tarifa.km_inicial = null;
      this.form_tarifa.km_final = null;
    },

    getFormFormato(){
      this.modal_title = `Formato de Tarifas`;
      this.form_file.file = {};
      document.getElementById("link_formato").value = "";
    },

    getDataDet(tarifa) {
      this.modal_title = `Histórico de Tarifa de Transporte`;
      this.cs_tarifa_transporte_id = tarifa.id;
      this.tarifa_estado = tarifa.estado;
      this.flag_form_detalle = 0;
      this.det_tarifa = tarifa.detalles;
    },

    getFormStatusDet(status, index = null) {
      this.flag_form_detalle = status;
      switch (status) {
        case 1:
          this.form_detalle = {
            id: null,
            cs_tarifa_transporte_id: this.cs_tarifa_transporte_id,
            valor: null,
            fecha_ini: null,
            fecha_fin: null,
            estado: 1,
          };
          break;
        case 2:
          this.form_detalle = {
            id: this.det_tarifa[index].id,
            cs_tarifa_transporte_id: this.det_tarifa[index].cs_tarifa_transporte_id,
            valor: this.det_tarifa[index].valor,
            fecha_ini: this.det_tarifa[index].fecha_ini,
            fecha_fin: this.det_tarifa[index].fecha_fin,
            estado: this.det_tarifa[index].estado,
          };
          break;
        default:
          break;
      }
    },

    // Funciones para validaciones de los datos
    validaKm() {
      let msg_error = "";
      if ( this.form_tarifa.km_inicial && this.form_tarifa.km_final ) {
        const inicial = this.listasForms.km_iniciales.find(ini => ini.numeracion == this.form_tarifa.km_inicial);
        const final = this.listasForms.km_finales.find(fin => fin.numeracion == this.form_tarifa.km_final);
        if (parseInt(inicial.descripcion) >= parseInt(final.descripcion)) {
           this.form_tarifa.km_final = null;
           msg_error = `Los kilómetros iniciales no puedes ser mayores que los kilómetros finales.`
        }
      }
      if (msg_error) {
        this.$swal({
          icon: "error",
          title: msg_error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    validaFechas(index) {
      let msg_error = "";
      if (this.form_detalle.fecha_ini) {
        const fecha_menor = new Date(this.form_detalle.fecha_ini);
        if (this.det_tarifa.length > 0) {
          let pos = index == null ? 0 : 1;
          if (this.det_tarifa[pos]) {
            const fecha_limite = new Date(this.det_tarifa[pos].fecha_fin);
            if (fecha_menor <= fecha_limite) {
              this.form_detalle.fecha_ini = null;
              msg_error = `La fecha inicio no puede ser menor a la fecha final de la tarifa actual...`;
            }
          }
        }
        if (this.form_detalle.fecha_ini && this.form_detalle.fecha_fin) {
          const fecha_mayor = new Date(this.form_detalle.fecha_fin);
          // Se valida que la fecha inicial sea menor que la fecha final
          if (fecha_menor > fecha_mayor) {
            this.form_detalle.fecha_fin = null;
            msg_error = `La fecha inicio no puede ser mayor a la fecha final...`;
          }
        }
      }

      if (msg_error) {
        this.$swal({
          icon: "error",
          title: msg_error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    // Funciones para las acciones de los formularios
    descargarFormatoTarifaTransporte() {
      const me = this;
      me.$parent.cargando = true;
      axios
        .get(`api/cs/tarifarios/tarifas/transportes`, {
          params: { cs_tarifario_id: me.cs_tarifario_id }
        })
        .then(function (response) {
          let data = response.data;
          me.$parent.cargando = false;
          location.href = data.url;
          me.$swal({
            icon: "success",
            title: data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function (error) {
          me.$parent.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    elegirDirectorio() {
      this.form_file.file = this.$refs.file.files[0];
    },

    cargarTarifas() {
      this.$parent.cargando = true;
      let me = this;
      let formData = new FormData();
      formData.append("file_formato", this.form_file.file);
      formData.append("cs_tarifario_id", this.cs_tarifario_id);
      axios
        .post(
          "/api/cs/tarifarios/tarifas/transportes/formato",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          let result_content = 
            response.data.plantilla ? 
              `<div>
                <p class="text-success">
                  <strong>Se actualizaron ${response.data.plantilla.length} registros.</strong>
                </p>
              </div>` :
              "";

          if (response.data.error && response.data.error.length > 0) {
            result_content += `<h4>Errores encontrados.</h4>`;
            response.data.error.forEach((err) => (result_content += `<div><small>${err}</small></div>`));
          }

          this.$swal({
            title: "Resultado de la carga.",
            html: result_content,
            icon: "success",
            showConfirmButton: false,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          });

          me.$parent.cargando = false;
          this.$refs.closeModalTransporteFormato.click();
          this.$parent.getIndex();
        })
        .catch(function (error) {
          me.$parent.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    save() {
      if (!this.$v.form_tarifa.$invalid) {
        this.$parent.cargando = true;
        axios({
          method: "POST",
          url: "/api/cs/tarifarios/tarifas/transportes",
          data: this.form_tarifa,
        })
          .then((response) => {
            this.$parent.cargando = false;
            if (!response.data.msg_error) {
              this.$refs.closeModalTransporte.click();
              this.$swal({
                icon: "success",
                title: "La tarifa se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
            } else {
              this.form_tarifa.nombre = null;
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    saveDetalle() {
      if (!this.$v.form_detalle.$invalid) {
        this.$parent.cargando = true;
        axios({
          method: "POST",
          url: "/api/cs/tarifarios/tarifas/transportes/detalle",
          data: this.form_detalle,
        })
          .then(() => {
            this.$parent.cargando = false;
            this.$refs.closeModalTransporteDetalle.click();
            this.$parent.getIndex();
            this.$swal({
              icon: "success",
              title: "El detalle de la tarifa se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },

  beforeMount() {
    this.getTiposTarifas();
    this.getTipoVehiculos();
    this.getRutas();
    this.getKmIniciales();
    this.getKmFinales();
    this.getEstados();
  },

  mounted() {
    this.getIndex();
  },
};
</script>
